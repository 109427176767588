<template>
  <div class="waning-exceed-push-detail">
    <van-row v-if="btn" type="flex" justify="center" align="center" class="card">
      <van-col span="17">
        <van-field style="border-radius: 6px 0 0 0;" label="客户名称" placeholder="请输入客户名称"
                   v-model="cardInfo.customerName"></van-field>
        <van-field label="推送人" placeholder="请输入推送人"
                   v-model="cardInfo.khfzr"></van-field>
        <van-cell-group>
          <van-field label="开始时间" placeholder="请输入开始时间" v-model="cardInfo.startTime"
                     @click="show = true"></van-field>
          <van-calendar :min-date="minDate" :default-date="defaultDate" v-model="show" @confirm="onConfirm" :show-confirm="false"/>
          <van-field style="border-radius: 0 0 0 6px;" placeholder="请输入结束时间" label="结束时间" @click="show1 = true"
                     v-model="cardInfo.endTime"></van-field>
          <van-calendar v-model="show1" @confirm="onConfirm1" :show-confirm="false"/>
        </van-cell-group>
      </van-col>
      <van-col span="7" @click="search()">
        <van-row>
          <van-icon size="50px" name="search"/>
        </van-row>
        <van-row>
          <span>
            <button style="background-color: #378FF2; border:none; font-size: 20px" >查询
            </button>
          </span>
        </van-row>
      </van-col>
    </van-row>
    <div v-if="this.list && this.list.length>=1"
         :style="{height: freshHeight + 180 +'px',overflowY: 'scroll', boxSizing: 'border-box'}">
      <warning-exceed-card :data="this.list" style="margin-bottom: 50px"/>
    </div>
    <div v-else style="background-color: #FFFFFF">
      <van-empty image="error" description="没有数据"/>
    </div>
  </div>
</template>

<script>
import {Toast} from "vant";
import WarningExceedCard from "@/components/Card/WarningExceedCard";
import {getChaoEWarningDetail} from '@/api/warningPanel'


export default {
  name: "WarningExceedPushDetail",
  components: {Toast, WarningExceedCard},
  data() {
    return {
      freshHeight: '',
      list: [],
      btn: false,
      cardInfo: {
        startTime: '',
        endTime: '',
        khfzr: '',
        customerName: ''
      },
      date: '',
      date1: '',
      show: false,
      show1: false,
      minDate: new Date('2010', '01', 1),
      defaultDate: new Date('2024', '01', 1),
    }
  },
  mounted() {
    this.freshHeight = document.documentElement.clientHeight - 50
    if(this.$route.params.id == 't0'){
      this.btn = false;
    }else{
      this.btn = true;
    }
    this.init()
  },
  methods: {
    init(){
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
        loadingType: 'spinner'
      });
      getChaoEWarningDetail({
        type: this.$route.params.id,
        khfzr: this.cardInfo.khfzr,
        customerName: this.cardInfo.customerName,
        startTime: this.cardInfo.startTime,
        endTime: this.cardInfo.endTime,
      }).then(res => {
        console.log(res)
        if (res.length > 0) {
          this.list = res
          Toast.success('加载成功');
        } else {
          Toast.success('暂无数据');
        }
      }).catch(err => {
        console.log(err)
        Toast.fail('加载失败');
      })
    },
    search(){
      this.list =[];
      getChaoEWarningDetail({
        type: this.$route.params.id,
        khfzr: this.cardInfo.khfzr,
        customerName: this.cardInfo.customerName,
        startTime: this.cardInfo.startTime,
        endTime: this.cardInfo.endTime,
      }).then(res => {
        console.log(res)
        if (res.length > 0) {
          this.list = res
          Toast.success('加载成功');
        } else {
          Toast.success('暂无数据');
        }
      }).catch(err => {
        console.log(err)
        Toast.fail('加载失败');
      })
    },
    onConfirm(date) {
      this.show = false;
      this.cardInfo.startTime = this.formatDate(date);
    },
    onConfirm1(date) {
      this.show1 = false;
      this.cardInfo.endTime = this.formatDate(date);
    },
    formatDate(date) {
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    },
  }
}
</script>

<style scoped>

.waning-exceed-push-detail{
  background: #FEFDFF;
}

.waning-exceed-push-detail .card {
  margin: 10px 5px;
  background: #378FF2;
  border-radius: 6px;
  box-shadow: 6px 6px 5px #888888;
}

</style>