<template>
    <div>
        <van-row class="item-card" v-for="(item,index) in data" v-bind:key="index">
            <van-col offset="1" span="23">
                <van-row class="title van-ellipsis">
                  <van-col span="24" class="van-ellipsis">往来单位：{{ item.customerName }}</van-col>
                </van-row>
                <van-row v-if="item.busgrpName" class="info">
                  业务组：<span class="push-person">{{item.busgrpName}}</span>
                </van-row>
                <van-row v-if="item.userName" class="info">
                  推送人：<span class="push-person">{{item.userName}}</span>
                </van-row>
                <van-row v-if="item.remainMny" class="info">
                  总营收余额：{{item.remainMny}}
                </van-row>
                <van-row v-if="item.maxmny" class="info">
                  授信额度：{{item.maxmny}}
                </van-row>
                <van-row v-if="item.ceje" class="info">
                  超限金额：{{item.ceje}}
                </van-row>
                <van-row v-if="item.actReceiveTime" class="info">
                  最后收款时间：{{item.actReceiveTime}}
                </van-row>
                <van-row v-if="item.actReceiveMny" class="info">
                  最后收款金额：{{item.actReceiveMny}}
                </van-row>
            </van-col>
        </van-row>
    </div>
</template>

<script>
    export default {
        name: "WarningExceedCard",
        props: {
            data: {type: Array}
        },
        data() {
            return {}
        },
        methods: {
          cardClick(item) {
            console.log(item)
            if (item && item.to && item.to.length > 0) {
              this.$emit('cardClickCallBack', item);
            }
          },
        },
        watch: {}
    }
</script>

<style scoped>
    .push-person {
        color: #007fff !important;
    }

    .item-card {
        background: #fff;
        padding: 5px 0;
        margin-top: 1px;
        text-align: left;
    }

    .title {
        font-size: 16px;
        color: #007fff;
        margin-top: 10px;
    }

    .info {
        padding: 5px 0;
        font-size: 12px;
        color: #999999;
    }

    .content{
      font-size: 10px;
    }

    .is-read {
      font-size: 18px;
      font-weight: bolder;
      margin-top: 5px;
      color: red;
    }
</style>

<style>
    .item-card {
      white-space: pre-wrap;
    }

    .item-card .wrap {
      /*word-wrap: break-word;*/
      /*word-break: break-all;*/
      white-space: pre-wrap;
    }
</style>
